import React, {useState, useRef, useEffect} from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import './update.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";

const UpdateRecipe = () => {
  const navigate = useNavigate();

  let { recipeId } = useParams();


  let [loading, setLoading] = useState(true);
  let [recipe, setRecipe] = useState({})
  let [options, setOptions] = useState();
  let [optionsub, setOptionsub] = useState();
  const token = localStorage.getItem('token');

  const name = useRef('');
  const picture = useRef('');
  const categoryId = useRef();
  const subcategoryId = useRef();

  const getCategoriesOptions = (categories) => {
    const categoriesOptions = categories.map(item => {
        return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    return categoriesOptions;
  }
  const getSubcategoriesOptions = (subcategories) => {
    const subcategoriesOptions = subcategories.map(item => {
        return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    return subcategoriesOptions;
  }

  const fetchData = () => {
    fetch(`${API_URL}/recipe/${recipeId}`, {
        method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
      })
        .then(response => response.json())
        .then(response => setRecipe(response))
        .catch(error => console.log(error))
        .finally(() => {
            setLoading(false);
        });
    fetch(`${API_URL}/categories`, {
        method: 'GET',
        headers : {
          'Authorization': 'Bearer ' + token,
        }
        })
        .then(response => response.json())
        .then((response) => {
            const categoriesOptions = getCategoriesOptions(response);
            setOptions(categoriesOptions);
        })
        .catch(error => console.log(error))
        .finally(() => {
            setLoading(false);
        });
    fetch(`${API_URL}/subcategories`, {
        method: 'GET',
        headers : {
          'Authorization': 'Bearer ' + token,
        }
        })
        .then(response => response.json())
        .then((response) => {
            const subcategoriesOptions = getSubcategoriesOptions(response);
            setOptionsub(subcategoriesOptions);
        })
        .catch(error => console.log(error))
        .finally(() => {
            setLoading(false);
        });
  }

  useEffect(() => {
    fetchData();
  }, [])

  const handleSumbit = (event) => {
        event.preventDefault();

        const postData = {
            name: name.current.value,
            picture: picture.current.value,
            categoryId: categoryId.current.value,
            subcategoryId: subcategoryId.current.value,
        };
        console.log(postData)

        fetch(`${API_URL}/recipe/${recipeId}`, {
            method: 'PUT',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(postData)
        } )
            .then(response => response.json())
            .then(response => setRecipe(response))

        navigate('/recipe');
  }

  return (
    <div className='update'>
        <Sidebar />
        <div className="updateContainer">
            <Navbar />
            <div className="form">
                <div className="top">
                    <h1 className="title">Update : {recipe.name}</h1>
                    <div className="updateButton">
                        <ArrowBackIosNewOutlinedIcon className='icon' />
                        <Link 
                            to={{
                                pathname: `/recipe`
                            }} 
                            className='link'
                        >
                            Retourner à la liste des recettes
                        </Link>
                    </div>
                </div>
                <form onSubmit={handleSumbit}>
                    <label>
                        <span>Nom</span>
                        <input
                            name="name"
                            type="text"
                            placeholder='Name'
                            defaultValue={recipe.name}
                            ref={name}
                        />
                    </label>
                    <label>
                        <span>Image</span>
                        <input
                            name="picture"
                            type="text"
                            placeholder='Picture (URL)'
                            defaultValue={recipe.picture}
                            ref={picture}
                        />
                    </label>
                    <label>
                        <span>Catégorie acutelle : {recipe.category?.name}</span>
                        {
                            loading ? (
                                <Spinner className='spinner' />
                            ) : (
                                <select name="" id="" ref={categoryId} 
                                defaultValue={recipe.category?.id} required>
                                    <option value="">Choisir la catégorie</option>
                                    {
                                    options
                                    }
                                </select>
                            )
                        }
                    </label>
                    <label>
                        <span>Sous catégorie acutelle : {recipe.subcategory?.name}</span>
                        {
                            loading ? (
                                <Spinner className='spinner' />
                            ) : (
                                <select name="" id="" ref={subcategoryId} 
                                defaultValue={recipe.subcategory?.id} required>
                                    <option value="">Choisir la sous-catégorie</option>
                                    {
                                    optionsub
                                    }
                                </select>
                            )
                        }
                    </label>
                    <button type='submit'>Mettre à jour</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default UpdateRecipe