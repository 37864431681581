import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import RecipesList from '../../components/home/RecipesList'
import './recipes.scss'

function Recipes() {
  return (
    <div className="recipes">
      <Sidebar />
      <div className="recipesContainer">
        <Navbar />
        <RecipesList />
      </div>
    </div>
  )
}

export default Recipes