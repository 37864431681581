import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import './subrecipe.scss'
import SubrecipesList from '../../components/home/SubrecipesList'

function Subrecipe() {
  return (
    <div className="recipes">
      <Sidebar />
      <div className="recipesContainer">
        <Navbar />
        <SubrecipesList />
      </div>
    </div>
  )
}

export default Subrecipe