import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from './screens/Dashboard/Dashboard';
import Recipes from './screens/Recipes/Recipes';
import SingleRecipe from './screens/Single/SingleRecipe';
import SingleSubcategory from './screens/Single/SingleSubcategory';
import Subcategories from './screens/Subcategories/Subcategories';
import UpdateSubcategory from './screens/Update/UpdateSubcategory';
import UpdateRecipe from './screens/Update/UpdateRecipe';
import NewSubcategory from './screens/New/NewSubcategory';
import NewRecipe from './screens/New/NewRecipe';
import Subrecipe from './screens/Subrecipe/Subrecipe';
import SingleSubrecipe from './screens/Single/SingleSubrecipe';
import UpdateSubrecipe from './screens/Update/UpdateSubrecipe';
import NewSubrecipe from './screens/New/NewSubrecipe';
import Auth from './screens/Auth/Auth';
import Admin from './screens/Admin/Admin';
import Login from './screens/Login/Login';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Auth />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/subcategory">
              <Route index element={<Subcategories />} />
              <Route path="new" element={<NewSubcategory />} />
              <Route path=':subcategoryId'>
                <Route index element={<SingleSubcategory />} />
                <Route path='update' element={<UpdateSubcategory />} />
              </Route>
            </Route>
            <Route path="/recipe">
              <Route index element={<Recipes />} />
              <Route path="new" element={<NewRecipe />} />
              <Route path=':recipeId'>
                <Route index element={<SingleRecipe />} />
                <Route path='update' element={<UpdateRecipe />} />
              </Route>
            </Route>
            <Route path="/subrecipe">
              <Route index element={<Subrecipe />} />
              <Route path="new" element={<NewSubrecipe />} />
              <Route path=':subrecipeId'>
                <Route index element={<SingleSubrecipe />} />
                <Route path='update' element={<UpdateSubrecipe />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
