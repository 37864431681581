import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import './single.scss'

const SingleSubcategory = () => {
  let { subcategoryId } = useParams();

  const [subcategory, setSubcategory] = useState([]);
  const token = localStorage.getItem('token');

  const fetchData = () => {
    fetch(`${API_URL}/subcategory/${subcategoryId}`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then(response => setSubcategory(response))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className='single'>
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <h1 className="title">Informations sur la sous-catégorie : {subcategory.name}</h1>

          <section>
            <div className="return">
              <Link
                to={{
                  pathname: `/subcategory`
                }}
                className='link'
              >
                Retourner sur toutes les catégories
              </Link>
            </div>

            <div className="editButton">
              <Link
                to={{
                  pathname: `update`
                }}
                className='link'
              >
                Modifier la sous-catégorie
              </Link>
            </div>
          </section>
        </div>
        <div className="center">
          <div className="item">
            <img 
              src={subcategory.picture}
              alt={subcategory.name}
              className="itemImg" 
            />
            <div className="details">
              <h2 className="itemTitle">{subcategory.name}</h2>
            </div>

           
          </div>
        </div>
        <div className="bottom"></div>
      </div>
    </div>
  )
}

export default SingleSubcategory