import React, { useEffect, useState } from 'react'
import './recipes.scss'

import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import API_URL from '../../API/Api';

function RecipesList() {

  const token = localStorage.getItem('token');

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 250 },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
          return(
            <div className="cellAction">
                <div className="viewButton">
                  <Link 
                      to={{
                          pathname: `/recipe/${params.row.id}`
                      }} 
                      className='link'
                  >
                   <VisibilityIcon className='icon' />
                  </Link>
                </div>
                <div className="updateButton">
                  <Link 
                      to={{
                          pathname: `/recipe/${params.row.id}/update`
                      }} 
                      className='link'
                  >
                   <ModeEditIcon className='icon' />
                  </Link>
                </div>
                <div className="deleteButton" onClick={(event) => {
                  event.preventDefault();
  
                  let res = window.confirm("Etes vous sur de supprimer l'élément ?");
                  //console.log(res)
                  if (res === true) 
                  {
                    fetch(`${API_URL}/recipe/${params.row.id}`, {
                        method: 'DELETE'
                    } )
                        .then(response => response.json())
  
                    window.location.reload();
                  }
                  
                }}>
                  <DeleteIcon className='icon' />
                </div>
            </div>
          )
      }
    },
  ];

  const [rows, setRows] = useState(true);

  const fetchData = () => {
    fetch(`${API_URL}/recipes`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then(response => setRows(response))
      .catch(error => console.log(error))
  }

  useEffect( () => {
    fetchData();
  }, []);

  return (
    <DataGrid
      className='datagrid'
      rows={rows}
      columns={columns}
      pageSize={7}
      rowsPerPageOptions={[7]}
    />
  )
}

export default RecipesList