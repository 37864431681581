import React, {useState, useRef} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import './new.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

function NewSubcategory() {

  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  let [subcategory, setSubcategory] = useState({})
  const name = useRef('');
  const picture = useRef('');

  const handleSumbit = (event) => {
    event.preventDefault();

    const postData = {
        name: name.current.value,
        picture: picture.current.value,
    };

    fetch(`${API_URL}/subcategory`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(postData)
    } )
        .then(response => response.json())
        .then(response => setSubcategory(response))

    navigate('/subcategory');
}

  return (
    <div className='new'>
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1 className='title'>Ajouter une nouvelle sous-catégorie</h1>
          <div className="updateButton">
              <ArrowBackIosNewOutlinedIcon className='icon' />
              <Link 
                  to={{
                      pathname: `/subcategory`
                  }} 
                  className='link'
              >
                  Retourner à la liste des sous-catégories
              </Link>
          </div>
        </div>
        <div className="bottom">
          <form onSubmit={handleSumbit}>
            <div className="width100">
              <label>
                  <span>Nom</span>
                  <input
                      name="name"
                      type="text"
                      placeholder='Name'
                      ref={name}
                      required
                  />
              </label>
            </div>
            <div className="width100">
              <label>
                  <span>Image</span>
                  <input
                      name="picture"
                      type="text"
                      placeholder='Picture (URL)'
                      ref={picture}
                      required
                  />
              </label>
            </div>
            <button type='submit'>Ajouter la sous-catégorie</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default NewSubcategory