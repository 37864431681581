import React from 'react'
import './navbar.scss'

import SearchIcon from '@mui/icons-material/Search';
import ModeNightIcon from '@mui/icons-material/ModeNight';

function Navbar() {
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder='Search...' />
          <SearchIcon className="icon" />
        </div>
        <div className="items">
          <div className="item">
            <ModeNightIcon className="icon" />
          </div>
          <div className="item">
            <a href="/profile">
              <img 
                src="https://app.destinationautourdumonde.fr/assets/images/icone-destination.png" 
                alt="avatar" 
                className='avatar'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar