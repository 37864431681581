import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom';

import parse from 'html-react-parser';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import './single.scss'

const SingleSubrecipe = () => {
  let { subrecipeId } = useParams();

  const [subrecipe, setSubrecipe] = useState([]);
  const token = localStorage.getItem('token');

  const fetchData = () => {
    fetch(`${API_URL}/subrecipesview/${subrecipeId}`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then(response => setSubrecipe(response))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className='single'>
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <h1 className="title">Informations sur le contenu pour la recette : {subrecipe.recipes?.name}</h1>

          <section>
            <div className="return">
              <Link
                to={{
                  pathname: `/subrecipe`
                }}
                className='link'
              >
                Retourner sur tous les contenus
              </Link>
            </div>

            <div className="editButton">
              <Link
                to={{
                  pathname: `update`
                }}
                className='link'
              >
                Modifier le contenu
              </Link>
            </div>
          </section>
        </div>
        <div className="center">
          <div className="item">
            <div className="details">
              <h2 className="itemTitle">Recette : {subrecipe.recipes?.name}</h2>
              <div className="content">
                <div className="left">
                  <h3>Ingrédients : </h3>
                  <p>{parse(`${subrecipe.ingredient}`)}</p>
                </div>
                <div className="right">
                  <h3>Préparation : </h3>
                  <p>{subrecipe.preparation}</p>
                </div>
              </div>
            </div>

           
          </div>
        </div>
        <div className="bottom"></div>
      </div>
    </div>
  )
}

export default SingleSubrecipe