import React from 'react'
import { useNavigate } from 'react-router-dom';
import './sidebar.scss'

import DashboardIcon from '@mui/icons-material/Dashboard';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';

function Sidebar() {

    const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    navigate('/');
  }

  return (
    <div className="sidebar">
        <div className="top">
            <div className="logo">Mes Envies et moi</div>
        </div>
        <div className="center">
            <ul>
                <li>
                    <DashboardIcon className='icon' />
                    <a href="/dashboard"><span>Dashboard</span></a>
                </li>
                <div className="title">Recettes</div>
                <li>
                    <ListIcon className='icon' />
                    <a href="/recipe"><span>Afficher</span></a>
                </li>
                <li>
                    <AddIcon className='icon' />
                    <a href="/recipe/new"><span>Ajouter</span></a>
                </li>
                <div className="title">Contenu</div>
                <li>
                    <ListIcon className='icon' />
                    <a href="/subrecipe"><span>Afficher</span></a>
                </li>
                <li>
                    <AddIcon className='icon' />
                    <a href="/subrecipe/new"><span>Ajouter</span></a>
                </li>
                <div className="title">Catégories</div>
                <li>
                    <ListIcon className='icon' />
                    <a href="/subcategory"><span>Afficher</span></a>
                </li>
                <li>
                    <AddIcon className='icon' />
                    <a href="/subcategory/new"><span>Ajouter</span></a>
                </li>
                <div className="title">Utilisateur</div>
                <li>
                    <LoginOutlinedIcon className="icon" />
                    <span onClick={logout}>Logout</span>
                </li>
            </ul>
        </div>
        <div className="bottom">
            ©2022 - Charlène Delporte
        </div>
    </div>
  )
}

export default Sidebar