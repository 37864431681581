import React from 'react'
import './subcategories.scss'
import SubcategoryList from '../../components/home/SubcategoryList'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'

function Subcategories() {
  return (
    <div className="subcategories">
      <Sidebar />
      <div className="subcategoriesContainer">
        <Navbar />
        <SubcategoryList />
      </div>
    </div>
  )
}

export default Subcategories