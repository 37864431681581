import React, {useState, useEffect} from 'react'

import API_URL from '../../API/Api'

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";
import { useNavigate } from 'react-router-dom';

const Auth = () => {

  const navigate = useNavigate();

  let [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');

  const fetchData = () => {
    setLoading(true)
    if(localStorage.getItem('token'))
    {
      fetch(`${API_URL}/api/usertoken`, {
        method: 'GET',
        headers : {
          'Authorization': 'Bearer ' + token,
        }
      })
        .then(navigate('/dashboard'))
        .catch(navigate('/admin'))
    }
    else{
      navigate('/admin')
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <>
      {
        loading ? (
          <Spinner className='spinner'/>
        ) : <></>
      }
    </>
  )
}

export default Auth