import React from 'react'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import './dashboard.scss';

import Subcategory from '../../components/home/SubcategoryList';
import Recipes from '../../components/home/RecipesList';

function Dashboard() {
  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboardContainer">
        <Navbar />
        <div className="contain">
          <div className="top">
            Dashboard
          </div>
          <div className="center">
            <div className="left">
              <span className="title">Catégories</span>
              <Subcategory />
            </div>
            <div className="right">
              <span className='title'>Recettes</span>
              <Recipes />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard