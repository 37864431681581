import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import parse from 'html-react-parser';

import './single.scss'

const SingleRecipe = () => {
  let { recipeId } = useParams();

  const [recipe, setRecipe] = useState([]);
  const token = localStorage.getItem('token');

  const fetchData = () => {
    fetch(`${API_URL}/recipe/${recipeId}`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
    })
      .then(response => response.json())
      .then(response => setRecipe(response))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className='single'>
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <h1 className="title">Informations sur la recette : {recipe.name}</h1>

          <section>
            <div className="return">
              <Link
                to={{
                  pathname: `/recipe`
                }}
                className='link'
              >
                Retourner sur toutes les recettes
              </Link>
            </div>

            <div className="editButton">
              <Link
                to={{
                  pathname: `update`
                }}
                className='link'
              >
                Modifier la recette
              </Link>
            </div>
          </section>
        </div>
        <div className="center">
          <div className="item">
            <img 
              src={recipe.picture}
              alt={recipe.name}
              className="itemImg" 
            />
            <div className="details">
              <h2 className="itemTitle">{recipe.name}</h2>
              <h3 className="itemTitle">
                {recipe.category?.name} - {recipe.subcategory?.name}
              </h3>

              <div className="content">
                {
                  recipe.subrecipesviews?.map( item => {
                    return(
                      <>
                        <h2 className="itemTitle">Identifiant du contenu : {item.id}</h2>
                        <div className="left">
                          <h3>Ingrédients</h3>
                          <p>{parse(`${item.ingredient}`)}</p>
                        </div>
                        <div className="right">
                          <h3>Préparation</h3>
                          <p>{parse(`${item.preparation}`)}</p>
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>

           
          </div>
        </div>
        <div className="bottom"></div>
      </div>
    </div>
  )
}

export default SingleRecipe