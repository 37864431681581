import React, {useState, useRef, useEffect} from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import './update.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";

const UpdateSubrecipe = () => {
  const navigate = useNavigate();

  let { subrecipeId } = useParams();


  let [loading, setLoading] = useState(true);
  let [subrecipe, setSubrecipe] = useState({});
  let [recipe, setRecipe] = useState({});
  let [options, setOptions] = useState();
  const token = localStorage.getItem('token');

  const ingredient = useRef('');
  const preparation = useRef('');
  const recipeId = useRef();

  const getRecipesOptions = (recipes) => {
    const recipesOptions = recipes.map(item => {
        return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    return recipesOptions;
  }

  const fetchData = () => {
    fetch(`${API_URL}/subrecipesview/${subrecipeId}`, {
        method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
      })
        .then(response => response.json())
        .then(response => setSubrecipe(response))
        .catch(error => console.log(error))
        .finally(() => {
            setLoading(false);
        });
    fetch(`${API_URL}/subrecipesview/${subrecipeId}/recipe`, {
        method: 'GET',
        headers : {
          'Authorization': 'Bearer ' + token,
        }
      })
        .then(response => response.json())
        .then(response => setRecipe(response))
        .catch(error => console.log(error))
        .finally(() => {
            setLoading(false);
        });
    fetch(`${API_URL}/recipes`, {
        method: 'GET',
        headers : {
          'Authorization': 'Bearer ' + token,
        }
        })
        .then(response => response.json())
        .then((response) => {
            const recipesOptions = getRecipesOptions(response);
            setOptions(recipesOptions);
        })
        .catch(error => console.log(error))
        .finally(() => {
            setLoading(false);
        });
  }

  useEffect(() => {
    fetchData();
  }, [])

  const handleSumbit = (event) => {
        event.preventDefault();

        const postData = {
            ingredient: ingredient.current.getContent(),
            preparation: preparation.current.getContent(),
            recipeId: recipeId.current.value,
        };
        console.log(postData)

        fetch(`${API_URL}/subrecipesview/${subrecipeId}`, {
            method: 'PUT',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(postData)
        } )
            .then(response => response.json())
            .then(response => setSubrecipe(response))

        navigate('/subrecipe');
  }

	const textToHtml = (text) => {
		const elem = document.createElement('div');
		return text.split(/\n\n+/).map((paragraph) => {
			return '<p>' + paragraph.split(/\n+/).map((line) => {
				elem.textContent = line;
				return elem.innerHTML;
			}).join('<br/>') + '</p>';
		}).join('');
	}

	const initialIngredient = `${subrecipe.ingredient}`
	const initialPreparation = `${subrecipe.preparation}`

  return (
    <div className='update'>
        <Sidebar />
        <div className="updateContainer">
            <Navbar />
            <div className="form">
                <div className="top">
                    <h1 className="title">Mise à jour du contenu pour : <br/> {recipe.name}</h1>
                    <div className="updateButton">
                        <ArrowBackIosNewOutlinedIcon className='icon' />
                        <Link 
                            to={{
                                pathname: `/subrecipe`
                            }} 
                            className='link'
                        >
                            Retourner à la liste des contenus
                        </Link>
                    </div>
                </div>
                <form onSubmit={handleSumbit}>
                    <label>
                        <span>Ingrédient</span>
                        <Editor
														apiKey='l4grsk94s5o1x52ye4egnqncyxrftsti2ux368r3e0pqu482'
                            onInit={(evt, editor) => ingredient.current = editor}
                            initialValue={initialIngredient}
                            init={{
															height: 500,
															menubar: false,
															selector: 'textarea',
															plugins: 'lists advlist',
															toolbar: 'bullist | formatselect | bold italic | alignleft aligncenter | code',
															advlist_bullet_styles: 'disc',
															content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </label>
                    <label>
                        <span>Préparation</span>
                        <Editor
														apiKey='l4grsk94s5o1x52ye4egnqncyxrftsti2ux368r3e0pqu482'
                            onInit={(evt, editor) => preparation.current = editor}
                            initialValue={initialPreparation}
                            init={{
															height: 500,
															menubar: false,
															selector: 'textarea',
															plugins: 'lists advlist',
															toolbar: 'bullist | formatselect | bold italic | alignleft aligncenter | code',
															advlist_bullet_styles: 'disc',
															content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px;  }'
                            }}
                        />
                    </label>
                    <label>
                        <span>Recette actuelle : {recipe.name}</span>
                        {
                            loading ? (
                                <Spinner className='spinner' />
                            ) : (
                                <select name="" id="" ref={recipeId} 
                                defaultValue={subrecipe.recipes?.id} required>
                                    <option value="">Choisir la recette</option>
                                    {
                                    options
                                    }
                                </select>
                            )
                        }
                    </label>
                    <button type='submit'>Mettre à jour</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default UpdateSubrecipe