import React, {useState, useRef, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import './new.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";

function NewRecipe() {

  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  let [loading, setLoading] = useState(true);
  let [options, setOptions] = useState();
  let [optionsub, setOptionsub] = useState();
  let [recipe, setRecipe] = useState({})
  const name = useRef('');
  const picture = useRef('');
  const categoryId = useRef();
  const subcategoryId = useRef();

  const getCategoriesOptions = (categories) => {
    const categoriesOptions = categories.map(item => {
      return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    return categoriesOptions;
  }
  const getSubcategoriesOptions = (subcategories) => {
    const subcategoriesOptions = subcategories.map(item => {
      return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    return subcategoriesOptions;
  }

  const fetchData = () => {
    fetch(`${API_URL}/categories`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
      })
      .then(response => response.json())
      .then((response) => {
          const categoriesOptions = getCategoriesOptions(response);
          setOptions(categoriesOptions);
      })
      .catch(error => console.log(error))
      .finally(() => {
          setLoading(false);
      });
    fetch(`${API_URL}/subcategories`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
      })
      .then(response => response.json())
      .then((response) => {
          const subcategoriesOptions = getSubcategoriesOptions(response);
          setOptionsub(subcategoriesOptions);
      })
      .catch(error => console.log(error))
      .finally(() => {
          setLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, [])

  const handleSumbit = (event) => {
    event.preventDefault();

    const postData = {
        name: name.current.value,
        picture: picture.current.value,
        categoryId: categoryId.current.value,
        subcategoryId: subcategoryId.current.value,
    };

    fetch(`${API_URL}/recipe`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(postData)
    } )
        .then(response => response.json())
        .then(response => setRecipe(response))

    navigate('/recipe');
}

  return (
    <div className='new'>
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1 className='title'>Ajouter une nouvelle recette</h1>
          <div className="updateButton">
              <ArrowBackIosNewOutlinedIcon className='icon' />
              <Link 
                  to={{
                      pathname: `/recipe`
                  }} 
                  className='link'
              >
                  Retourner à la liste des recettes
              </Link>
          </div>
        </div>
        <div className="bottom">
          <form onSubmit={handleSumbit}>
            <div className="width100">
              <label>
                  <span>Nom</span>
                  <input
                      name="name"
                      type="text"
                      placeholder='Name'
                      ref={name}
                      required
                  />
              </label>
            </div>
            <div className="width100">
              <label>
                  <span>Image</span>
                  <input
                      name="picture"
                      type="text"
                      placeholder='Picture (URL)'
                      ref={picture}
                      required
                  />
              </label>
            </div>
            <div className="width100">
              <label>
                <span>Catégorie</span>
                {
                  loading ? (
                    <Spinner className='spinner' />
                  ) : (
                    <select name="" id="" ref={categoryId}>
                        <option value="">Choisir la catégorie</option>
                        {
                        options
                        }
                    </select>
                  )
                }
              </label>
            </div>
            <div className="width100">
              <label>
                <span>Sous-catégorie </span>
                {
                  loading ? (
                    <Spinner className='spinner' />
                  ) : (
                    <select name="" id="" ref={subcategoryId}>
                        <option value="">Choisir la sous-catégorie</option>
                        {
                        optionsub
                        }
                    </select>
                  )
                }
              </label>
            </div>
            <button type='submit'>Ajouter la sous-catégorie</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default NewRecipe