import React, {useState, useRef, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom';
import './new.scss'

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

import API_URL from '../../API/Api';

import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'

import Spinner from "react-activity/dist/Dots";
import "react-activity/dist/library.css";

function NewSubrecipe() {

  const navigate = useNavigate();

  let [loading, setLoading] = useState(true);
  let [options, setOptions] = useState();
  let [subrecipe, setSubrecipe] = useState({});
  const token = localStorage.getItem('token');

  const ingredient = useRef('');
  const preparation = useRef('');
  const recipeId = useRef();

  const getRecipesOptions = (recipes) => {
    const recipesOptions = recipes.map(item => {
      return (<option key={item.id} value={item.id}>{item.name}</option>)
    });
    return recipesOptions;
  }

  const fetchData = () => {
    fetch(`${API_URL}/recipes`, {
      method: 'GET',
      headers : {
        'Authorization': 'Bearer ' + token,
      }
      })
      .then(response => response.json())
      .then((response) => {
          const recipesOptions = getRecipesOptions(response);
          setOptions(recipesOptions);
      })
      .catch(error => console.log(error))
      .finally(() => {
          setLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, [])

  const handleSumbit = (event) => {
    event.preventDefault();

    const postData = {
        ingredient: ingredient.current.value,
        preparation: preparation.current.value,
        recipeId: recipeId.current.value,
    };

    fetch(`${API_URL}/subrecipesview`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json' ,
          'Authorization': 'Bearer ' + token,
        },
        body: JSON.stringify(postData)
    } )
        .then(response => response.json())
        .then(response => setSubrecipe(response))

    navigate('/subrecipe');
}

  return (
    <div className='new'>
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1 className='title'>Ajouter un nouveau contenu</h1>
          <div className="updateButton">
              <ArrowBackIosNewOutlinedIcon className='icon' />
              <Link 
                  to={{
                      pathname: `/subrecipe`
                  }} 
                  className='link'
              >
                  Retourner à la liste des contenus
              </Link>
          </div>
        </div>
        <div className="bottom">
          <form onSubmit={handleSumbit}>
            <div className="width100">
              <label>
                  <span>Ingredient</span>
                  <textarea ref={ingredient} name="ingredient" placeholder='ingredient' />
              </label>
            </div>
            <div className="width100">
              <label>
                  <span>Préparation</span>
                  <textarea ref={preparation} name="preparation" placeholder='preparation' />
              </label>
            </div>
            <div className="width100">
              <label>
                <span>Recette</span>
                {
                  loading ? (
                    <Spinner className='spinner' />
                  ) : (
                    <select name="" id="" ref={recipeId}>
                        <option value="">Choisir la recette</option>
                        {
                        options
                        }
                    </select>
                  )
                }
              </label>
            </div>
            <button type='submit'>Ajouter le contenu</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default NewSubrecipe